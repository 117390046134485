<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 用户查询 </template>
      <template #input>
        <a-input placeholder="请输入机构名称" v-model="orgName" />
        <a-input placeholder="请输入用户账号" v-model="email" />
        <a-range-picker @change="onRegisterTime" :placeholder="['注册开始时间', '注册结束时间']" />
        <a-button type="primary" class="btn" :loading="loading" @click="onSearch()">搜索</a-button>
        <!-- <a-button
          class="all_boder_btn"
          style="margin-right: 20px"
          @click="onEmpty()"
          >重置</a-button
        > -->
        <a-button class="all_boder_btn" @click="isExportShow = true"
          >导出</a-button
        >
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item,index) => index"
        :columns="columns"
        :data-source="tableData"
        @change="onPage"
        :loading="loading"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          {{ (pageNumber - 1) * pageSize + i + 1 }}
        </template>
        <template slot="city" slot-scope="ite,item">
          {{item.orgProvice}}{{item.orgCity}}{{item.orgArea?item.orgArea:''}}
        </template>
        <template slot="operation" slot-scope="item">
          <div class="blueText">
            <span @click="goPage(item.userId)">详情</span>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 商品弹窗 -->
    <a-modal
      v-model="category"
      width="688px"
      title="选择商品"
      :centered="true"
      :closable="false"
    >
      <div class="search-box">
        <a-select
          class="search-input"
          placeholder="请选择类型"
          v-model="categorytype"
          allowClear
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option
            v-for="item in cgoodList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.classify }}
          </a-select-option>
        </a-select>
        <a-input
          class="search-input"
          placeholder="请输入名称"
          v-model="categoryname"
          allowClear
        />
        <a-button class="search-btn" type="primary" @click="oncSearch()"
          >搜索</a-button
        >
      </div>

      <div class="table-box">
        <a-table
          class="table-template"
          :row-selection="rowSelection"
          :rowKey="(item) => item.productId"
          :columns="categorycolumns"
          :data-source="categoryData"
          :pagination="{
            total: ctotal,
            current: cpageNumber,
            pageSize: cpageSize,
            showTotal: (res) => `共${ctotal}条`,
          }"
          bordered
          @change="oncPage"
        >
          <template slot="index" slot-scope="item, row, index">
            {{ (cpageNumber - 1) * cpageSize + index + 1 }}
          </template>

          <template slot="productType" slot-scope="item">
            <!-- 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他 -->
            <span v-if="item == 1">课程</span>
            <span v-else-if="item == 2">图书</span>
            <span v-else-if="item == 3">教具</span>
            <span v-else-if="item == 4">模拟考试</span>
            <span v-else-if="item == 5">电子照片</span>
            <span v-else-if="item == 6">延期</span>
            <span v-else-if="item == 7">补考</span>
            <span v-else-if="item == 8">直播</span>
            <span v-else-if="item == 9">其他</span>
            <span v-else>-</span>
          </template>
        </a-table>
      </div>

      <template slot="footer">
        <a-button type="primary" @click="categoryOk">确认</a-button>
        <a-button @click="categoryCancel">取消</a-button>
      </template>
    </a-modal>

    <!-- 导出弹窗 -->
    <Derive ref="export"
      :visible="isExportShow"
      :handleCancel="modelCancel"
      @onexportList="onexportList()"
      :dataList="[
        {
          title: '报名信息',
          options: exportList,
        },
      ]"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "机构名称",
    align: "center",
    dataIndex: "orgName",
  },
  {
    title: "用户账号",
    align: "center",
    dataIndex: "email",
  },
  {
    title: "地区",
    align: "center",
    scopedSlots: { customRender: "city" },
  },
  {
    title: "员工人数",
    align: "center",
    dataIndex: "orgPersonNum",
  },
  {
    title: "联系人",
    align: "center",
    dataIndex: "contactName",
  },
  {
    title: "联系方式",
    align: "center",
    dataIndex: "contactTell",
  },
  {
    title: "注册时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "操作",
    align: "center",
    width: "220px",
    scopedSlots: { customRender: "operation" },
  },
];
const categorycolumns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "商品类型",
    align: "center",
    dataIndex: "productType",
    scopedSlots: {
      customRender: "productType",
    },
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "productName",
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
import Derive from "@/components/derive.vue"; // 导出组件

export default {
  // 可用组件的哈希表
  components: { HeaderBox, Derive },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      orgName: "",
      email: "",
      startTime: "",
      endTime: "",

      userType: undefined,
      userTypes: undefined,
      userExport: undefined,
      dateType: undefined,
      examName: undefined,
      examStatus: undefined,
      isPass: undefined,
      buyMethod: undefined,
      studyStatus: undefined,
      orderType: undefined,
      productCode: "",
      tradeName: undefined,
      bjqs: undefined,
      clear: undefined,
      // 商品弹窗
      category: false,
      categoryname: undefined,
      categorytype: undefined,
      categorycolumns, // 选择商品
      categoryData: [],
      ctotal: 0,
      cpageNumber: 1, // 页码
      cpageSize: 5, // 页数
      // selectedRowKeys: [],
      // 商品类别
      cgoodList: [
        { id: 0, classify: "全部" },
        { id: 1, classify: "课程" },
        { id: 2, classify: "图书" },
        { id: 3, classify: "教具" },
        { id: 4, classify: "模拟考试" },
        { id: 5, classify: "电子照片" },
        { id: 6, classify: "延期" },
        { id: 7, classify: "补考" },
        { id: 8, classify: "直播" },
      ],

      examList: [],
      courseList: [],
      numList: [],

      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数

      isExportShow: false, // 导出弹窗,
      certifItem: {}, // 操作对象
      exportList: [
        { name: "用户姓名", value: "name", checked: true, disabled: false },
        { name: "手机号", value: "mobile", checked: true, disabled: false },
        { name: "身份证", value: "idNo", checked: true, disabled: false },
        { name: "性别", value: "sex", checked: true, disabled: false },
        { name: "学历", value: "degree", checked: true, disabled: false },
        { name: "身份", value: "userType", checked: true, disabled: false },

        { name: "民族", value: "nation", checked: true, disabled: false },
        {
          name: "籍贯",
          value: "pgAddressName",
          checked: true,
          disabled: false,
        },
        {
          name: "工作年限",
          value: "workYears",
          checked: true,
          disabled: false,
        },
        { name: "职务", value: "occupationName", checked: true, disabled: false },
        { name: "生日", value: "birthday", checked: true, disabled: false },
        {
          name: "机构名称",
          value: "comAuthName",
          checked: true,
          disabled: false,
        },

        { name: "工作单位", value: "work", checked: true, disabled: false },
        {
          name: "所在地",
          value: "locationName",
          checked: true,
          disabled: false,
        },
        { name: "用户名", value: "userName", checked: true, disabled: false },
        {
          name: "孩子生日",
          value: "childBirthday",
          checked: true,
          disabled: false,
        },
        { name: "总成绩", value: "totalscore", checked: true, disabled: false },
        { name: "考试名称", value: "examname", checked: true, disabled: false },

        { name: "考试日期", value: "examdate", checked: true, disabled: false },
        {
          name: "考试场次",
          value: "examdatetime",
          checked: true,
          disabled: false,
        },
        {
          name: "考试状态",
          value: "examstatus",
          checked: true,
          disabled: false,
        },
        {
          name: "是否第三方考试人员",
          value: "tripartUser",
          checked: true,
          disabled: false,
        },
        { name: "考试次数", value: "examNum", checked: true, disabled: false },
        {
          name: "最后一次考试时间",
          value: "endTime",
          checked: true,
          disabled: false,
        },

        {
          name: "证书名称",
          value: "certiName",
          checked: true,
          disabled: false,
        },
        {
          name: "证书编号",
          value: "certiCode",
          checked: true,
          disabled: false,
        },
        {
          name: "发证时间",
          value: "getcertifTime",
          checked: true,
          disabled: false,
        },
        {
          name: "课程名称",
          value: "courseName",
          checked: true,
          disabled: false,
        },
        {
          name: "学习进度",
          value: "studyProgress",
          checked: true,
          disabled: false,
        },
        {
          name: "报名课程时间",
          value: "createTime",
          checked: true,
          disabled: false,
        },

        {
          name: "课程有效期",
          value: "validTime",
          checked: true,
          disabled: false,
        },
        {
          name: "课程期数",
          value: "courseOpenTime",
          checked: true,
          disabled: false,
        },
        {
          name: "培训地点",
          value: "courseAddress",
          checked: true,
          disabled: false,
        },
        {
          name: "商品名称",
          value: "productName",
          checked: true,
          disabled: false,
        },
        { name: "支付状态", value: "status", checked: true, disabled: false },
        { name: "支付金额", value: "payPrice", checked: true, disabled: false },

        {
          name: "订单编号",
          value: "orderCode",
          checked: true,
          disabled: false,
        },
        {
          name: "订单来源",
          value: "orderSource",
          checked: true,
          disabled: false,
        },
        {
          name: "支付时间",
          value: "paymentTime",
          checked: true,
          disabled: false,
        },
        {
          name: "商品单价",
          value: "productPrice",
          checked: true,
          disabled: false,
        },
        {
          name: "支付方式",
          value: "payMethod",
          checked: true,
          disabled: false,
        },
        {
          name: "支付交易号",
          value: "tradeId",
          checked: true,
          disabled: false,
        },

        { name: "优惠金额", value: "couponPrice", checked: true, disabled: false },
        { name: "活动来源", value: "source", checked: true, disabled: false },
        {
          name: "订单类型",
          value: "orderType",
          checked: true,
          disabled: false,
        },
        { name: "用户类型", value: "type", checked: true, disabled: false },
        { name: "收货地址", value: "address", checked: true, disabled: false },
      ],
    };
  },
  // 事件处理器
  methods: {
    // 选择时间
    onRegisterTime(date, dateString) {
      this.startTime = dateString[0]; // 开始日期
      this.endTime = dateString[1]; // 结束日期
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getList();
    },
    // 重置
    onEmpty() {
      this.orgName = "";
      this.email = "";
      this.startTime = "";
      this.endTime = "";
      this.pageNumber = 1;
      this.getList();
    },
    // 搜索
    onSearch() {
      this.pageNumber = 1;
      this.getList();
      this.userExport = this.userTypes;
      this.userType = [];
    },
    // 详情
    goPage(id) {
      this.$router.push({ path: "/admin/Mechanism/userDetail?userId=" + id });
    },
    getExam() {
      this.$ajax({
        url: "/hxclass-management/exam/config/exam/tab/list",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.examList = res.data;
        }
      });
    },
    getNum() {
      this.$ajax({
        url: "hxclass-management/course/open-time/select/bycode",
        params: {
          productCode: this.productCode,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.numList = res.data;
        }
      });
    },
    // 商品弹窗
    oncPage(e) {
      this.cpageNumber = e.current;
      this.cpageSize = e.pageSize;
      this.categoryFilling();
    },
    oncSearch() {
      this.categoryFilling();
    },
    categoryOk(e) {
      this.category = false;
      if (this.productType == 1) {
        this.getNum();
      }
    },
    categoryCancel(e) {
      this.category = false;
    },
    categoryFilling() {
      this.category = true;
      this.$ajax({
        url: "/hxclass-management/product-archives/manage/select-win",
        params: {
          name: this.categoryname,
          type: this.categorytype == 0 ? "" : this.categorytype,
          page: this.cpageNumber,
          size: this.cpageSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.ctotal = res.data.total;
          this.categoryData = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    select(e, v) {
      if (v.length > 0) {
        this.productCode = v[0].productCode;
        this.productType = v[0].productType;
      }
      this.tradeName = v[0].productName;
    },
    modelCancel() {
      this.isExportShow = false;
      this.$refs.export.checkedList();
    },
    // 导出功能
    onexportList() {
      let filter = this.exportList.filter((item) => {
        return item.checked;
      });
      let arr = filter?.map((item, index) => {
        if (item.checked) {
          return {
            name: item.name,
            value: item.value,
          };
        }
      });
      window.open(
          this.$config.target + "/hxclass-management/user/info/export?columnJson=" +
          encodeURIComponent(JSON.stringify(arr)) +
          "&name=" +
          encodeURIComponent(this?.name) +
          "&mobile=" +
          encodeURIComponent(this?.mobile) +
          "&idNo=" +
          encodeURIComponent(this?.idNo) +
          "&userType=" +
          encodeURIComponent(this.userExport ? this.userExport : "") +
          "&dateType=" +
          encodeURIComponent(this.dateType == undefined ? "" : this.dateType) +
          "&startTime=" +
          encodeURIComponent(this?.startTime) +
          "&endTime=" +
          encodeURIComponent(this?.endTime) +
          "&examName=" +
          encodeURIComponent(this.examName == undefined ? "" : this.examName) +
          "&examStatus=" +
          encodeURIComponent(
            this.examStatus == undefined ? "" : this.examStatus
          ) +
          "&isPass=" +
          encodeURIComponent(this.isPass ? String(this.isPass) : "") +
          "&buyMethod=" +
          encodeURIComponent(
            this.buyMethod == undefined ? "" : this.buyMethod
          ) +
          "&studyStatus=" +
          encodeURIComponent(
            this.studyStatus == undefined ? "" : this.studyStatus
          ) +
          "&orderType=" +
          encodeURIComponent(
            this.orderType == undefined ? "" : this.orderType
          ) +
          "&productCode=" +
          encodeURIComponent(this?.productCode) +
          "&bjqs=" +
          encodeURIComponent(this.bjqs == undefined ? "" : this.bjqs)
      );
      this.userExport = [];
    },
    getList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/organization/info/manage/select/orgUser",
        params: {
          orgName: this.orgName,
          email: this.email,
          startTime: this.startTime,
          endTime: this.endTime,
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getExam();
    this.getList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {
    rowSelection() {
      return {
        type: "radio",
        onChange: this.select,
      };
    },
  },
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.time {
  display: inline-block;
  /deep/ .ant-select {
    margin: 0 0px 14px 0 !important;
  }
  /deep/.ant-select-selection--single {
    height: 32px !important;
  }
  /deep/.ant-calendar-picker {
    width: 380px !important;
  }
}
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
/deep/.right-box {
  padding-bottom: 5px !important;
}
.modalDiv {
  > p {
    font-weight: 500;
    color: #333333;
  }
  .code-item {
    > span {
      display: inline-block;
      min-width: 100px;
      margin: 5px 0;
      margin-right: 15px;
    }
  }
}

/deep/.ant-modal-body {
  overflow: hidden;
}
/deep/.ant-modal-title {
  text-align: center;
  color: #000000;
}
/deep/.ant-modal-header {
  border-bottom: none;
}
/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}
/deep/ .ant-modal-body {
  padding: 14px 36px 0px;
}
.table-template {
  /deep/.ant-table {
    border: none;
  }
}
.search-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .search-input {
    width: 36%;
  }
}
</style>
